<template>
    <div>
        <div class="page-header">
            <h3>账户报表</h3>
            <div class="action">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <form class="search" method="get" @submit.prevent="handleQuery">
            <div class="search-item">
                <label>开始时间:</label>
                <input type="date" class="form-control" name="start_date" v-model="query.start_date">
            </div>
            <div class="search-item">
                <label>结束时间:</label>
                <input type="date" class="form-control" name="end_date" v-model="query.end_date">
            </div>
            <div class="search-item buttons">
                <button type="submit" class="btn btn-primary">查询</button>
                <span class="divider"></span>
                <button type="button" class="btn btn-light">清空</button>
            </div>
        </form>
        <div class="table table-fixed">
            <table>
                <tr>
                    <th width="90" class="fixed-left">日期</th>
                    <th width="80">展现</th>
                    <th width="76">点击</th>
                    <th width="70">点击率</th>
                    <th width="90">消费</th>
                    <th width="70">ACP</th>
                    <th width="120">推送/激活数</th>
                    <th width="110">推送/注册数</th>
                    <th width="100">推送/付费数</th>
                    <th width="70">退款数</th>
                    <th width="90">退款金额</th>
                    <th width="90">付费总额</th>
                    <th width="70">ROI</th>
                    <th width="90">收益额</th>
                    <th width="88" class="fixed-right">操作</th>
                </tr>
                <tr v-for="(item, date) in list" :key="date">
                    <td class="fixed-left">{{ date }}</td>
                    <td>{{ getReportData(item).show | number }}</td>
                    <td>{{ getReportData(item).click | number }}</td>
                    <td>{{ getReportData(item).click | pct(getReportData(item).show) }}</td>
                    <td>{{ getReportData(item).cost | currency }}</td>
                    <td>{{ getReportData(item).cost | avg(getReportData(item).click) | currency }}</td>
                    <td>{{ getConversionData(item, '1').push_count | pushCount(getConversionData(item, '1').count) }}
                    </td>
                    <td>{{ getConversionData(item, '2').push_count | pushCount(getConversionData(item, '2').count) }}
                    </td>
                    <td>{{ getConversionData(item, '3').push_count | pushCount(getConversionData(item, '3').count) }}
                    </td>
                    <td>{{ getConversionData(item, '4').count | number }}</td>
                    <td>{{ getConversionData(item, '4').value | currency }}</td>
                    <td>{{ getConversionData(item, '3').value | currency }}</td>
                    <td>{{ getConversionData(item, '3').value | roi(getConversionData(item, '4').value, getReportData(item).cost) }}</td>
                    <td :class="'pct pct-' + sum.income_center_rate + '-' + item.income_rate">
                        <span class="text">{{ item.income | currency }}</span>
                    </td>
                    <td class="fixed-right">
                        <router-link class="btn btn-light btn-s"
                            :to="{ path: '/conversions', query: { account_id: query.account_id, start_date: date, end_date: date } }">转化记录</router-link>
                    </td>
                </tr>
                <tr v-if="sum">
                    <td class="fixed-left">合计：</td>
                    <td>{{ sum.report_show | number }}</td>
                    <td>{{ sum.report_click | number }}</td>
                    <td>{{ sum.report_click | pct(sum.report_show) }}</td>
                    <td>{{ sum.report_cost | currency }}</td>
                    <td>{{ sum.report_cost | avg(sum.report_click) | currency }}</td>
                    <td>{{ sum.conversions_1_push_count | pushCount(sum.conversions_1_count) }}</td>
                    <td>{{ sum.conversions_2_push_count | pushCount(sum.conversions_2_count) }}</td>
                    <td>{{ sum.conversions_3_push_count | pushCount(sum.conversions_3_count) }}</td>
                    <td>{{ sum.conversions_4_count | number }}</td>
                    <td>{{ sum.conversions_4_value | currency }}</td>
                    <td>{{ sum.conversions_3_value | currency }}</td>
                    <td>{{ sum.conversions_3_value | roi(sum.conversions_4_value, sum.report_cost) }}</td>
                    <td>{{ sum.conversions_3_value | profit(sum.conversions_4_value, sum.report_cost) | currency }}</td>
                    <td class="fixed-right"></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { formatDate } from '@/filters'
import { mapMutations } from 'vuex'

export default {
    name: 'AccountReport',
    data() {
        return {
            query: {},
            list: [],
            sum: null
        }
    },
    mounted() {
        this.query = { ...this.getDefaultQuery(), ...this.$route.query }
        this.fetchList()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        getDefaultQuery() {
            let query = {
                page: 1,
                page_size: 10
            }
            const now = new Date()
            query.end_date = formatDate(now)
            query.start_date = formatDate(new Date(now.getTime() - 86400000 * 7))
            return query
        },
        handleQuery() {
            this.fetchList()
        },
        getReportData(item) {
            if (!item || !item.report) {
                return {}
            }
            return item.report
        },
        getConversionData(item, type) {
            if (!item || !item.conversions || !item.conversions[type]) {
                return {}
            }
            return item.conversions[type]
        },
        fetchList() {
            this.startLoading()
            this.sum = null
            this.$api.post('account/report', this.query).then(res => {
                if (res.data.success) {
                    let list = res.data.data
                    this.sum = {}
                    if (Object.keys(list).length > 0) {
                        this.sum.income_center_rate = 0
                        let max_income = 0
                        let min_income = 0
                        let item;
                        for (let date in list) {
                            item = list[date]
                            if (item == null) {
                                continue;
                            }
                            list[date].income = this.$options.filters.profit(this.getConversionData(item, '3').value, this.getConversionData(item, '4').value, this.getReportData(item).cost)
                            if (min_income > list[date].income) {
                                min_income = list[date].income
                            }
                            if (max_income < list[date].income) {
                                max_income = list[date].income
                            }
                            if (item.report) {
                                if (this.sum.report_show == undefined || this.sum.report_click == undefined || this.sum.report_cost == undefined) {
                                    this.sum.report_show = 0
                                    this.sum.report_click = 0
                                    this.sum.report_cost = 0
                                }
                                this.sum.report_show += parseInt(item.report.show)
                                this.sum.report_click += parseInt(item.report.click)
                                this.sum.report_cost += parseFloat(item.report.cost)
                            }
                            if (item.conversions) {
                                if (item.conversions['1']) {
                                    if (this.sum.conversions_1_push_count == undefined || this.sum.conversions_1_count == undefined) {
                                        this.sum.conversions_1_push_count = 0
                                        this.sum.conversions_1_count = 0
                                    }
                                    this.sum.conversions_1_push_count += parseInt(item.conversions['1'].push_count)
                                    this.sum.conversions_1_count += parseInt(item.conversions['1'].count)
                                }
                                if (item.conversions['2']) {
                                    if (this.sum.conversions_2_push_count == undefined || this.sum.conversions_2_count == undefined) {
                                        this.sum.conversions_2_push_count = 0
                                        this.sum.conversions_2_count = 0
                                    }
                                    this.sum.conversions_2_push_count += parseInt(item.conversions['2'].push_count)
                                    this.sum.conversions_2_count += parseInt(item.conversions['2'].count)
                                }
                                if (item.conversions['3']) {
                                    if (this.sum.conversions_3_push_count == undefined || this.sum.conversions_3_count == undefined || this.sum.conversions_3_value == undefined) {
                                        this.sum.conversions_3_push_count = 0
                                        this.sum.conversions_3_count = 0
                                        this.sum.conversions_3_value = 0
                                    }
                                    this.sum.conversions_3_push_count += parseInt(item.conversions['3'].push_count)
                                    this.sum.conversions_3_count += parseInt(item.conversions['3'].count)
                                    this.sum.conversions_3_value += parseFloat(item.conversions['3'].value)
                                }
                                if (item.conversions['4']) {
                                    if (this.sum.conversions_4_count == undefined || this.sum.conversions_4_value == undefined) {
                                        this.sum.conversions_4_count = 0
                                        this.sum.conversions_4_value = 0
                                    }
                                    this.sum.conversions_4_count += parseInt(item.conversions['4'].count)
                                    this.sum.conversions_4_value += parseFloat(item.conversions['4'].value)
                                }
                            }
                        }
                        let total_income = max_income
                        if (min_income < 0) {
                            total_income += Math.abs(min_income)
                            this.sum.income_center_rate = parseInt(Math.abs(min_income) / total_income * 100);
                        }
                        for (let date in list) {
                            item = list[date]
                            if (item == null) {
                                list[date] = {
                                    income_rate: 0
                                }
                                continue;
                            }
                            if (item.income >= 0) {
                                list[date].income_rate = parseInt(item.income / max_income * 100);
                            } else {
                                list[date].income_rate = -parseInt(Math.abs(item.income) / Math.abs(min_income) * 100);
                            }
                        }
                    }
                    this.list = list
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error.message)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>
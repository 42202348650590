<template>
    <div class="login">
        <div class="login-content">
            <form method="post" class="login-form" @submit.prevent="handleSubmit">
                <div class="form-heading">
                    <h1>
                        <font color="#07AA71">AD</font>STAT
                    </h1>
                </div>
                <div class="form-item">
                    <label>用户名</label>
                    <input type="text" class="form-control" placeholder="请输入用户名" v-model="username" required>
                </div>
                <div class="form-item">
                    <label>密码</label>
                    <input type="password" class="form-control" placeholder="请输入密码" v-model="password" required>
                </div>
                <div class="form-commit">
                    <button type="submit" class="btn btn-primary">登 录</button>
                </div>
            </form>
        </div>
        <footer>
            <div class="copy">版权所有 &copy; 2022-2024 &nbsp; <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAAWCAYAAABEx1soAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAATgSURBVFhH3VlNSFVREPYJurNWtdCIQCKjdqFEfxvbRBQYtCkoc/OUFinRWg1auVAJUSHMWrQJCvqhjW76I5KgTaiLwMLaBBG5U/D1zWXmMve883ff80X04OL1nDlz5nxn5jtz5hbq/oPfzMzMjo2NjY9YStPm5uapvr6+N+aypqen36OtHf3HbP21gqFgU8zG1BWLxQ7XxFNTU2cKhcKTUql0tre392keA2msloeeVujZw23b8f8Bfm9B+yL0nwzph82XITOLZxh2D2l56YOuWei64tMF2RL6F8y1uzCRtag1HMT7Nsy1DXO1/RWA2egQRrb+BWp0LLa9EoWWMRkwYwEGsGMA8prDhsTuhoaG0wnAEj4+g7Ej49TvUZoMJznsXL/ogu42vC/i+Y6+h4bnrqDts7Th/WdM+EJnv/J4rXI37OsiD0T/W+6wtaVjIL+CDRwLAJaBxsBiGP9/cNlecw9WVJIBfou8L6PGNlfs/BzqnexANmc4zw5ETjKPJ5ENUWQKcCiMRVFIDt6Q2TTID8GYQdOzBRnxnJCh5obQwba+vv4K7atY6DP2QjkX0s00AWY+vor5VhFp50y9eSkiZHcKhnHwjPDEN8SAxsbGdz09PT8UqR8i4PAkISJy5oEXAjDU7/N0ogr0XxCwYjwYMku0KXhoE8oO55oBrBeylVkEFjQHD+t07XQ1AJvgxwAcoqZAhKYHYqzdBTEqNHHsIUd6NE3IAWpSR6UUkecwCq1J+jV9McAuDv4mGU00wDYjMPgutftyxsnJyaP19fWjSNwHfCc/DP4NVU1bCDDl0J2x4EXKzQtd5KWIgP6FDAfTxSHSIKuYDcTQoZhjvrLkP8fYaNG8AMP7H0P5V5oA+B3Bn3ZpoxQwBZhP5cPRlmQFR6BsnyWDoBz4fkBnC/qbYdQydJC3W3/o/xS6hVVoe2ZYNRxsow1rHpzX0BDP+vTFcpmDyny3qdAyrBGRl4NxW9tJ2RVNFgS42gPExbOyUuJ2eOkXXSuwGYW2R5B7Sblt5OakYRpClUOZrrg+gIO1CJtTBQGmay1CcW+MkYaMlSK0jCq4LFMRRIGeeKFO4yS1g8yAD+RKvd/Fs2RTDg5egt10eBPFJT8vwLXgYJmY6xFULqRfB4yiZN9plNzSiNch1A35e7ZNrzLiMl6aU1c37JmFU1D2kVb6vADH5sM+77ZRRAgslxdyjTcpBLlqvGps1RTBN9So9A8b/wtmJbfYEN2ZdYM28S42Pqk08WKfQ+EDW8hKdQvX6VtC+MpDiU+7XLVYX5hzrv2CdOEwabXoLqMXnwOoiLLWfA2bkzNArX1CIonPCKra7Q9FY1keLLcak4uU0pQX9SYQb2LCXQD5uOVUzYSSBiHEo1xvGKU0TnO3i/OqAViBSbXmZJ0cgdPsJPOwYxAXrNfoLzsInRShwrhZPMVG9lQZQ/+EAjAlekUxycT6UNOgawD0gnxVKc4+us2KXE7eNLE3C+30ReQ2CWGei2YhiKP0BPUz2GVfcpwAS8qhF8lX3DW0ZYrkykr6NEJFnPQzjJpgnMbRVZqq+jq0ZS5jtWs2CtAyPO6SIySr5mC5+mPOzBzmrpAc1n3dVup0Asze16r5lUPzJiZocoQdgT8H7ywaAGa83BwrJUZppxsanjsxXzJiwv9fk/kDSgvREn0mMCUAAAAASUVORK5CYII="
                    width="44" height="11" /> &nbsp; 保留一切权力</div>
            <a class="icp" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2020029715号-3</a>
        </footer>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'Auth',
    data() {
        return {
            username: '',
            password: ''
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
        if (this.userInfo) {
            this.redirect()
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading', 'setUserInfo']),
        redirect() {
            const redirect = this.$route.query.redirect
            this.$router.replace(redirect || '/accounts')
        },
        handleSubmit() {
            this.startLoading()
            this.$api.post('auth/login', {
                'username': this.username,
                'password': this.password
            }).then(res => {
                if (res.data.success) {
                    this.setUserInfo(res.data.data.user_info)
                    this.redirect()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error.message)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>
<template>
  <div class="loading">
      <div class="progress" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
      return {
          progress: 0,
          intervalID: null
      }
  },
  methods: {
      start() {
          this.progress = 0
          this.intervalID = setInterval(this.increment, 10)
      },
      increment() {
          if (this.progress < 100) {
              this.progress++
          } else {
              this.destroyInterval()
          }
      },
      done() {
          this.destroyInterval()
          this.progress = 100
          setTimeout(() => {
              this.progress = 0
          }, 300)
      },
      destroyInterval() {
          clearInterval(this.intervalID)
          this.intervalID = null
      }
  }
}
</script>
<template>
    <form class="form" method="post" @submit.prevent="handleSubmit">
        <div class="form-item">
            <label>账户名:</label>
            <input type="text" class="form-control" v-model.trim="data.username">
            <label>媒体类型:</label>
            <Selection :items="{ '1': '百度推广' }" placeholder="请选择媒体类型" v-model="data.media_type"></Selection>
        </div>
        <div class="form-item">
            <label>是否启用:</label>
            <Selection :items="['未启用', '正常', '配置失效']" placeholder="全部" v-model="data.status" width="120">
            </Selection>
            <label>标签:</label>
            <input type="text" class="form-control" v-model.trim="data.tags" placeholder="多个标签以“;”分号隔开。">
        </div>
        <div class="form-item">
            <label>账户密码:</label>
            <input type="text" class="form-control" v-model.trim="data.password">
            <label>媒体账户ID:</label>
            <input type="text" class="form-control" v-model.trim="data.media_user_id">
        </div>
        <div class="form-item">
            <label>密保主手机:</label>
            <input type="text" v-model.trim="data.mobile" class="form-control">
            <label>密保子手机:</label>
            <input type="text" v-model.trim="data.sub_mobile" class="form-control">
        </div>
        <div class="form-item" v-if="userInfo.role_permissions.includes('ADMIN')">
            <label>可操作用户:</label>
            <ul class="children">
                <li v-for="(item, index) in users" :key="index" class="form-check">
                    <label><input type="checkbox" v-model="data.user_ids" :value="index">{{ item }}</label>
                </li>
            </ul>
        </div>
        <div class="form-action">
            <button type="submit" class="btn btn-primary">保 存</button>
            <button type="button" class="btn btn-light" @click="$router.back()">取 消</button>
        </div>
    </form>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Selection from './Selection.vue'

export default {
    name: 'AccountForm',
    components: {
        Selection
    },
    computed: {
        ...mapState(['userInfo'])
    },
    props: {
        value: Object
    },
    data() {
        return {
            data: this.value,
            users: []
        }
    },
    mounted() {
        this.fetchUsers()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),

        fetchUsers() {
            this.$api.get('users').then(res => {
                if (res.data.success) {
                    this.users = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        }
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>
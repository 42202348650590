import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import axios from 'axios'
import * as filters from './filters'

Vue.use(Vuex)

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

let localUserInfo = null
if (localStorage.getItem('userInfo') != null) {
	localUserInfo = JSON.parse(localStorage.getItem('userInfo'))
}
const store = new Vuex.Store({
	state: {
		isLoading: false,
		userInfo: localUserInfo
	},
	getters: {
		getToken: state => state.userInfo && state.userInfo.token
	},
	mutations: {
		startLoading(state) {
			state.isLoading = true
		},
		finishLoading(state) {
			state.isLoading = false
		},
		setUserInfo(state, data) {
			state.userInfo = data
			if (data == null) {
				localStorage.removeItem("userInfo")
			} else {
				localStorage.setItem("userInfo", JSON.stringify(data))
			}
		}
	}
})

Vue.use(VueRouter)

const getToken = () => {
	return store.getters.getToken
}

const API = axios.create({
	baseURL: process.env.VUE_APP_API_BASE,
	headers: {
		'Content-Type': 'application/json',
	},
	timeout: 1000 * 60
});
API.interceptors.request.use(
	config => {
		const token = getToken()
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
);
Vue.prototype.$api = API

import App from './App.vue'

import Home from './pages/Home.vue'

import AuthLogin from './pages/Auth/login.vue'

import AccountIndex from './pages/Account/index.vue'
import AccountNew from './pages/Account/new.vue'
import AccountEdit from './pages/Account/edit.vue'

import AppIndex from './pages/App/index.vue'
import AppNew from './pages/App/new.vue'
import AppEdit from './pages/App/edit.vue'

import AccountReportIndex from './pages/AccountReport/index.vue'

import ConversionIndex from './pages/Conversion/index.vue'

const routes = [
	{ path: '/login', component: AuthLogin },
	{ path: '/', component: Home, meta: { requiresAuth: true } },
	{ path: '/accounts', component: AccountIndex, meta: { requiresAuth: true } },
	{ path: '/accounts/new', component: AccountNew, meta: { requiresAuth: true } },
	{ path: '/accounts/:id/edit', component: AccountEdit, meta: { requiresAuth: true } },
	{ path: '/accounts/:account_id/apps', component: AppIndex, meta: { requiresAuth: true } },
	{ path: '/accounts/:account_id/apps/new', component: AppNew, meta: { requiresAuth: true } },
	{ path: '/accounts/:account_id/apps/:id/edit', component: AppEdit, meta: { requiresAuth: true } },
	{ path: '/account_reports', component: AccountReportIndex, meta: { requiresAuth: true } },
	{ path: '/conversions', component: ConversionIndex, meta: { requiresAuth: true } }
]

const router = new VueRouter({
	mode: 'hash',
	routes
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	if (requiresAuth && !store.state.userInfo) {
		next({
			path: '/login',
			query: { redirect: to.fullPath }
		})
	} else {
		next()
	}
})

router.afterEach(() => {
	window.scroll(0, 0)
})

Vue.config.productionTip = false

new Vue({
	router,
	store: store,
	render: h => h(App),
}).$mount('#app')

<template>
    <div>
        <div class="page-header">
            <h3>添加应用</h3>
            <div class="action">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <AppForm v-model="data" @handleSubmit="handleSubmit" />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import AppForm from '../../components/AppForm.vue'

export default {
    name: 'Account',
    components: {
        AppForm
    },
    data() {
        return {
            data: {
                account_id: this.$route.params.account_id,
                user_ids: []
            }
        }
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        handleSubmit(data) {
            this.startLoading()
            this.$api.post('app/create', data).then(res => {
                alert(res.data.message)
                if (res.data.success) {
                    this.$router.back()
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>
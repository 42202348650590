<template>
    <div>
        <div class="page-header">
            <h3>编辑账户</h3>
            <div class="action">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <AccountForm v-model="data" @handleSubmit="handleSubmit" />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import AccountForm from '../../components/AccountForm.vue'

export default {
    name: 'Account',
    components: {
        AccountForm
    },
    data() {
        return {
            data: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchData() {
            this.startLoading()
            this.$api.post('account/info', { id: this.$route.params.id }).then(res => {
                if (res.data.success) {
                    res.data.data.user_ids = res.data.data.user_ids.split(',')
                    this.data = res.data.data
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleSubmit(data) {
            this.startLoading()
            this.$api.post('account/update', data).then(res => {
                alert(res.data.message)
                if (res.data.success) {
                    this.$router.back()
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>